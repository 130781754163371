import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.css'

ReactDOM.render(
    <App/>,
    document.getElementById('root')
)


/**  aqui puedo envolver la app con REDUX, etc **/